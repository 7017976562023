@import 'bootstrap/dist/css/bootstrap-utilities.css';

.gaggl {
  --card-bg-color: rgb(14, 35, 45);

  --color-red: #ff3b30;
  --color-green: #4cd964;
  --color-blue: #2196f3;
  --color-pink: #ff2d55;
  --color-yellow: #ffcc00;
  --color-orange: #ff9500;
  --color-purple: #9c27b0;
  --color-deeppurple: #673ab7;
  --color-lightblue: #5ac8fa;
  --color-teal: #009688;
  --color-lime: #cddc39;
  --color-deeporange: #ff6b22;
  --color-white: #ffffff;
  --color-black: #000000;

  --color-red-rgb: 255, 59, 48;
  --color-green-rgb: 76, 217, 100;
  --color-blue-rgb: 33, 150, 243;
  --color-pink-rgb: 255, 45, 85;
  --color-yellow-rgb: 255, 204, 0;
  --color-orange-rgb: 255, 149, 0;
  --color-purple-rgb: 156, 39, 176;
  --color-deeppurple-rgb: 103, 58, 183;
  --color-lightblue-rgb: 90, 200, 250;
  --color-teal-rgb: 0, 150, 136;
  --color-lime-rgb: 205, 220, 57;
  --color-deeporange-rgb: 255, 107, 34;
  --color-white-rgb: 255, 255, 255;
  --color-black-rgb: 0, 0, 0;

  --f7-toolbar-height: 64px;
  --f7-input-bg-color: var(--card-bg-color);
  --f7-textarea-padding-vertical: 0.25rem;
  --f7-input-padding-left: 0.5rem;
  --f7-input-padding-right: 0.5rem;

  input[type='date'],
  input[type='tel'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='password'],
  input[type='text'],
  textarea,
  .select {
    background-color: var(--f7-input-bg-color);
    width: 100%;
    height: 44px;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: var(--title-color);
    font-weight: 400;
    font-size: 1rem;
    &:disabled {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .input-variant-outlined {
    input,
    textarea {
      border: 1px solid #fff;
      background-color: transparent;
    }
  }
  .select {
    display: flex;
    align-items: center;
    select {
      width: 100%;
    }
    div:last-child {
      margin-left: auto;
    }
  }
  * {
    box-sizing: border-box;
  }

  --f7-message-bubble-font-size: 16px;
  .messages {
    // padding-bottom: 2rem;
  }
  .messagebar-area {
    margin: 0;
  }
  .messagebar textarea {
    width: 100%;
    flex-shrink: 1;
    background-color: var(--f7-messagebar-textarea-bg-color);
    border-radius: var(--f7-messagebar-textarea-border-radius);
    padding: var(--f7-messagebar-textarea-padding);
    height: var(--f7-messagebar-textarea-height);
    color: var(--f7-messagebar-textarea-text-color);
    font-size: var(--f7-messagebar-textarea-font-size);
    line-height: var(--f7-messagebar-textarea-line-height);
    border: var(--f7-messagebar-textarea-border);
  }
}

.dark {
  background-color: rgb(3, 17, 24) !important;
}

.color-primary {
  color: var(--color-deeppurple);
}

.ml-auto {
  margin-left: auto;
}

.gaggl {
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Rubik', sans-serif !important;
    // font-family: 'Inter', sans-serif !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // font-family: 'Inter', sans-serif !important;
    font-family: 'Rubik', sans-serif !important;
    font-weight: 600;
  }
}

.small {
  font-size: 0.875rem;
}

.markdown-block {
  ul,
  ol {
    padding-inline-start: 1.25rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
}
